<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.33268 21.6538C3.7247 20.5776 2.66602 18.7448 2.66602 16.6647C2.66602 13.5403 5.05469 10.9738 8.10567 10.6912C8.72977 6.89535 12.0263 3.99951 15.9993 3.99951C19.9724 3.99951 23.2689 6.89535 23.893 10.6912C26.944 10.9738 29.3327 13.5403 29.3327 16.6647C29.3327 18.7448 28.274 20.5776 26.666 21.6538M10.666 21.3308L15.9993 15.9981M15.9993 15.9981L21.3327 21.3308M15.9993 15.9981V27.9967" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconUploadCloud"
}
</script>
